import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const bayar = createAsyncThunk("pay/bayar", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pay/${val}`);
    return response.data;
});

export const allPayment = createAsyncThunk("pay/allPayment", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/pay/all`,val);
    return response.data;
});

export const infoPay = createAsyncThunk("pay/infoPay", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/pay/info/${val}`);
    return response.data;
});


export const getListPaymentUserAll = createAsyncThunk("pay/getPaymentUserAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/vpay`);
    return response.data;
});

export const reportPaymentAll = createAsyncThunk("pay/reportPaymentAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/report/payment`);
    return response.data;
});
export const reportPaymentDate = createAsyncThunk("pay/reportPaymentDate", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/report/payment/date`,val);
    return response.data;
});

export const reportPaymentDetailAll = createAsyncThunk("pay/reportPaymentDetailAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/report/payment/detail`);
    return response.data;
});


export const reportPaymentDetailDate = createAsyncThunk("pay/reportPaymentDetailAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/report/payment/detail/date`,val);
    return response.data;
});

export const listTransactionsXendit = createAsyncThunk("pay/listTransactionsXendit", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.post(`v1/pay/transaction`,val);
    return response.data;
});

export const tambahPembayaran = createAsyncThunk("pay/tambahPembayaran", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //const response = await jwtAuthAxios.post(`v1/payment-detail/trx/${val}`);
    const response = await jwtAuthAxios.post('v1/add/pay',val);
    return response.data;
});
export const tambahPembayaranMultiple = createAsyncThunk("pay/tambahPembayaran", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //const response = await jwtAuthAxios.post(`v1/payment-detail/trx/${val}`);
        const response = await jwtAuthAxios.post('v1/add/pay/multiple',val);
    return response.data;
});

const initialState = {
    loadingPay : false,
    loadingAddPay : false,
    loading : false,
    responsePay : {},
    responseInfoPay : {},
    responsePaymentUserAll : [],
    reportPaymentDetail : [],
    responseAddPay : {},
    responseListTransactionsXendit: [],
}

const paySlice = createSlice({
    name:"paySlice",
    initialState: initialState,
    extraReducers: {
        [allPayment.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [allPayment.fulfilled] : (state, action) => {
            state.responseInfoPay = action.payload;
            state.loadingPay = false;
        },
        [allPayment.pending] : (state, action) => {
            state.loadingPay = true;
        },

        [listTransactionsXendit.pending] : (state, action) => {
            state.loading = true;
        },
        [listTransactionsXendit.fulfilled] : (state, action) => {
            state.responseListTransactionsXendit = action.payload;
            state.loading = false;
        },
        [listTransactionsXendit.pending] : (state, action) => {
            state.loading = true;
        },

        [infoPay.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [infoPay.fulfilled] : (state, action) => {
            state.responseInfoPay = action.payload;
            state.loadingPay = false;
        },
        [infoPay.pending] : (state, action) => {
            state.loadingPay = true;
        },



        [bayar.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [bayar.fulfilled] : (state, action) => {
            state.responsePay = action.payload;
            state.loadingPay = false;
        },
        [bayar.pending] : (state, action) => {
            state.loadingPay = true;
        },

        [getListPaymentUserAll.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [getListPaymentUserAll.fulfilled] : (state, action) => {
            state.responsePaymentUserAll = action.payload;
            state.loadingPay = false;
        },
        [getListPaymentUserAll.rejected] : (state, action) => {
            state.loadingPay = true;
        },

        [reportPaymentAll.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [reportPaymentAll.fulfilled] : (state, action) => {
            state.responsePaymentUserAll = action.payload;
            state.loadingPay = false;
        },
        [reportPaymentAll.rejected] : (state, action) => {
            state.loadingPay = true;
        },

        [reportPaymentDate.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [reportPaymentDate.fulfilled] : (state, action) => {
            state.responsePaymentUserAll = action.payload;
            state.loadingPay = false;
        },
        [reportPaymentDate.rejected] : (state, action) => {
            state.loadingPay = true;
        },

        [tambahPembayaran.pending] : (state, action) => {
            state.loadingAddPay = true;
        },
        [tambahPembayaran.fulfilled] : (state, action) => {
            state.responseAddPay = action.payload;
            state.loadingAddPay = false;
        },
        [tambahPembayaran.pending] : (state, action) => {
            state.loadingAddPay = true;
        },

        [tambahPembayaranMultiple.pending] : (state, action) => {
            state.loadingAddPay = true;
        },
        [tambahPembayaranMultiple.fulfilled] : (state, action) => {
            state.responseAddPay = action.payload;
            state.loadingAddPay = false;
        },
        [tambahPembayaranMultiple.pending] : (state, action) => {
            state.loadingAddPay = true;
        },

        [reportPaymentDetailAll.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [reportPaymentDetailAll.fulfilled] : (state, action) => {
            state.reportPaymentDetail = action.payload;
            state.loadingPay = false;
        },
        [reportPaymentDetailAll.pending] : (state, action) => {
            state.loadingPay = true;
        },

        [reportPaymentDetailDate.pending] : (state, action) => {
            state.loadingPay = true;
        },
        [reportPaymentDetailDate.fulfilled] : (state, action) => {
            state.reportPaymentDetail = action.payload;
            state.loadingPay = false;
        },
        [reportPaymentDetailDate.pending] : (state, action) => {
            state.loadingPay = true;
        },
    }
})

export const paySelector = (state) => state.pay;
export default paySlice.reducer;