import {AUTH_PREFIX_PATH,PUBLIC_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const publicRoute = [
    // {
    //     key: 'default.upload',
    //     path: `${PUBLIC_PREFIX_PATH}/upload-default`,
    //     component: React.lazy(() => import('views/public-views/upload')),
    // },
    // {
    //     key: 'default.testing',
    //     path: `${PUBLIC_PREFIX_PATH}/testing`,
    //     component: React.lazy(() => import('views/public-views/testing')),
    // },
    {
        key: 'default.home',
        path: `${PUBLIC_PREFIX_PATH}/home`,
        component: React.lazy(() => import('views/public-views/home')),
    },
    {
        key: 'default.home.prodi',
        path: `${PUBLIC_PREFIX_PATH}/home/prodi`,
        component: React.lazy(() => import('views/public-views/home/prodi')),
    },
    {
        key: 'default.home.faq',
        path: `${PUBLIC_PREFIX_PATH}/home/faq`,
        component: React.lazy(() => import('views/public-views/home/faq')),
    },
    {
        key: 'default.home.kontak',
        path: `${PUBLIC_PREFIX_PATH}/home/kontak`,
        component: React.lazy(() => import('views/public-views/home/kontak')),
    },
    {
        key: 'default.home.alur-registrasi',
        path: `${PUBLIC_PREFIX_PATH}/home/alur-pendaftaran`,
        component: React.lazy(() => import('views/public-views/home/alur-pendaftaran')),
    },
    {
        key: 'default.home.biaya-pendidikan',
        path: `${PUBLIC_PREFIX_PATH}/home/biaya-pendidikan`,
        component: React.lazy(() => import('views/public-views/home/biaya-pendidikan')),
    },
    {
        key: 'default.home.cara-pembayaran',
        path: `${PUBLIC_PREFIX_PATH}/home/cara-pembayaran`,
        component: React.lazy(() => import('views/public-views/home/cara-pembayaran')),
    },
    {
        key: 'default.home.jadwal-registrasi',
        path: `${PUBLIC_PREFIX_PATH}/home/jadwal-pendaftaran`,
        component: React.lazy(() => import('views/public-views/home/jadwal-pembayaran')),
    },

    {
        key: 'default.home.jadwal-registrasi',
        path: `${PUBLIC_PREFIX_PATH}/home/syarat-pendaftaran`,
        component: React.lazy(() => import('views/public-views/home/syarat-pendaftaran')),
    },


    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'login-1',
        path: `${AUTH_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'register-check',
        path: `${AUTH_PREFIX_PATH}/verify-email/:id`,
        component: React.lazy(() => import('views/auth-views/authentication/verify-email')),
    },
    {
        key: 'verify-token',
        path: `${AUTH_PREFIX_PATH}/register-check/:id`,
        component: React.lazy(() => import('views/auth-views/authentication/check-form')),
    },
    // {
    //     key: 'test-1',
    //     path: `${AUTH_PREFIX_PATH}/test-1`,
    //     component: React.lazy(() => import('views/master-view/cbt/Welcome')),
    // },
    {
        key: 'register-1',
        path: `${AUTH_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'register-admission',
        path: `${AUTH_PREFIX_PATH}/register-admission`,
        component: React.lazy(() => import('views/auth-views/authentication/register-admission')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'resend-verification',
        path: `${AUTH_PREFIX_PATH}/resend-verification`,
        component: React.lazy(() => import('views/auth-views/authentication/resend-verification-email')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },
]