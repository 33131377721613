import jwtAuthAxios from "../../../services/jwtAuth";
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

export const getPayment = createAsyncThunk("payment/getAll", async() => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //const response = await jwtAuthAxios.get(`v1/pembayaran/get-user`);
    const response = await jwtAuthAxios.get(`v1/payment/user`);
    return response.data;
});
export const getPaymentByUserId = createAsyncThunk("payment/getPaymentByUserId", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //const response = await jwtAuthAxios.get(`v1/pembayaran/get-user`);
    const response = await jwtAuthAxios.get(`v1/payment/user/byid/${id}`);
    return response.data;
});


export const getPaymentById = createAsyncThunk("payment/getPaymentById", async(id) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    //const response = await jwtAuthAxios.get(`v1/pembayaran/get-user`);
    const response = await jwtAuthAxios.get(`v1/payment/${id}`);
    return response.data;
});
export const getPaymentDetail = createAsyncThunk("payment/getDetailAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/payment-detail/trx/${val}`);
    return response.data;
});
export const getPaymentDetailXendit = createAsyncThunk("payment/getDetailAll", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.get(`v1/payment-detail/xdt/trx/${val}`);
    return response.data;
});
export const updatePaymentDetail = createAsyncThunk("payment/updatePaymentDetail", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.put(`v1/payment-detail`,val);
    return response.data;
});
export const deletePaymentDetail = createAsyncThunk("payment/deletePaymentDetail", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.delete(`v1/payment-detail/${val}`);
    return response.data;
});
export const deletePaymentMaster = createAsyncThunk("payment/deletePaymentMaster", async(val) => {
    let token = await localStorage.getItem('auth_token');
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await jwtAuthAxios.delete(`v1/payment/${val}`);
    return response.data;
});


const initialState = {
    loading : false,
    dataList : [],
    detailList : [],
    dataPayment : {}
}

const paymentSlice = createSlice({
    name:"paymentSlice",
    initialState: initialState,
    extraReducers: {
        [getPayment.pending] : (state, action) => {
            state.loading = true;
        },
        [getPayment.fulfilled] : (state, action) => {
            state.dataList = action.payload;
            state.loading = false;
        },

        [getPayment.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentById.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentById.fulfilled] : (state, action) => {
            state.dataPayment = action.payload;
            state.loading = false;
        },

        [getPaymentByUserId.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentByUserId.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentByUserId.fulfilled] : (state, action) => {
            state.dataList = action.payload;
            state.loading = false;
        },

        [getPaymentById.pending] : (state, action) => {
            state.loading = true;
        },

        [getPaymentDetail.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentDetail.fulfilled] : (state, action) => {
            state.detailList = action.payload;
            state.loading = false;
        },
        [getPaymentDetail.rejected] : (state, action) => {
            state.loading = true;
        },


        [getPaymentDetailXendit.pending] : (state, action) => {
            state.loading = true;
        },
        [getPaymentDetailXendit.fulfilled] : (state, action) => {
            state.detailList = action.payload;
            state.loading = false;
        },
        [getPaymentDetailXendit.rejected] : (state, action) => {
            state.loading = true;
        },

        [updatePaymentDetail.pending] : (state, action) => {
            state.loading = true;
        },
        [updatePaymentDetail.fulfilled] : (state, action) => {
            state.dataPayment = action.payload;
            state.loading = false;
        },
        [updatePaymentDetail.rejected] : (state, action) => {
            state.loading = true;
        },
        // delete payment detail
        [deletePaymentDetail.pending] : (state, action) => {
            state.loading = true;
        },
        [deletePaymentDetail.fulfilled] : (state, action) => {
            state.dataPayment = action.payload;
            state.loading = false;
        },
        [deletePaymentDetail.rejected] : (state, action) => {
            state.loading = true;
        },
        // delete payment
        [deletePaymentMaster.pending] : (state, action) => {
            state.loading = true;
        },
        [deletePaymentMaster.fulfilled] : (state, action) => {
            //state.dataPayment = action.payload;
            state.loading = false;
        },
        [deletePaymentMaster.rejected] : (state, action) => {
            state.loading = true;
        },
    }
})

export const paymentSelector = (state) => state.payment;
export default paymentSlice.reducer;